<script lang="ts" setup>
import type { NavigationItem } from '~/types/api'

interface Props {
  ignore: HTMLButtonElement[]
}
const { closeMenu, links } = useHeader()
const { isSize } = useScreen()

const menu = ref<HTMLDivElement>()
const { ignore } = defineProps<Props>()
onClickOutside(
  menu,
  () => {
    closeMenu()
  },
  {
    ignore,
  },
)

const indexes = ref<number[]>([])

const hasPrevious = computed(() => indexes.value.length > 0 && !isSize('md'))

function handleClick(index: number) {
  if (isSize('md')) {
    indexes.value = [index]
    return
  }
  indexes.value.push(index)
}

function removeIndex() {
  indexes.value.pop()
}

const currentItem = computed<NavigationItem | null>(() => {
  if (!hasPrevious.value) {
    return null
  }

  let item: NavigationItem | null = null
  let carry: NavigationItem[] = links.value

  indexes.value.forEach((value, index) => {
    if (index === indexes.value.length - 1) {
      item = carry[value]
    } else {
      carry = carry[value].children as NavigationItem[]
    }
  })

  return item
})

const localePath = useLocalePath()
</script>

<template>
  <div class="fixed inset-0 top-32 hidden bg-black/75 md:block" />

  <div
    ref="menu"
    class="fixed inset-x-0 bottom-0 top-36 z-50 flex md:right-auto md:top-32"
  >
    <nav class="flex size-full bg-white">
      <nav class="flex w-full flex-col justify-between md:w-56">
        <ul class="h-full divide-y overflow-y-scroll">
          <HeaderMenuItem
            v-if="hasPrevious"
            :label="$t('global.back')"
            icon="i-far-angle-left"
            @click="removeIndex"
          />
          <HeaderMenuItem
            v-if="hasPrevious && currentItem"
            :label="`${$t('global.all')} ${currentItem.name}`"
            :to="localePath(`/${currentItem.type}/${currentItem.slug}`)"
          />
          <HeaderMenuItem
            v-for="(link, i) in currentItem ? currentItem.children : links"
            :key="`l1-${i}`"
            :label="link.name"
            trailing-icon="i-far-angle-right"
            block
            :to="
              link.children ? null : localePath(`/${link.type}/${link.slug}`)
            "
            @click="link.children ? handleClick(i) : closeMenu()"
          />
        </ul>

        <ul class="shrink-0 divide-y border-t">
          <HeaderMenuItem
            icon="i-far-arrows-rotate"
            :label="$t('navigation.trade-in')"
            :to="localePath('/resale/trade-in')"
            @click="closeMenu"
          />
          <HeaderMenuItem
            icon="i-far-hand-holding-dollar"
            :label="$t('navigation.sell-your-phone')"
            :to="localePath('/resale/buyback')"
            @click="closeMenu"
          />
        </ul>
      </nav>

      <nav
        v-if="isSize('md') && indexes.length > 0"
        class="divide-y divide-gray-800 overflow-y-scroll"
      >
        <div
          v-for="(link, i) in links[indexes[0]].children"
          :key="`l2-${i}`"
          class="flex px-6 py-8"
        >
          <div class="w-28 shrink-0 pr-4">
            <UButton
              variant="link"
              color="black"
              :to="localePath(`/${link.type}/${link.slug}`)"
              size="xl"
              class="underline"
              :padded="false"
              @click="closeMenu"
            >
              {{ link.name }}
            </UButton>
          </div>
          <ul class="w-full columns-3">
            <li v-for="(child, j) in link.children" :key="`l3-${j}`">
              <NuxtLink
                :to="localePath(`/${child.type}/${child.slug}`)"
                class="underline-offset-4 hover:underline"
                @click="closeMenu"
              >
                {{ child.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </nav>
    </nav>
  </div>
</template>
