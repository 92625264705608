<template>
  <UButton
    variant="link"
    color="black"
    :padded="false"
    v-bind="$attrs"
    class="flex h-14 cursor-pointer px-4"
    size="lg"
    as="li"
    :ui="{
      block: 'justify-between',
    }"
  >
    <slot />
  </UButton>
</template>
