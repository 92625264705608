<script lang="ts" setup>
import type { DropdownItem } from '#ui/types'

interface Locale {
  name: string
  code: string
  flag: string
}

interface LocaleMap {
  [key: string]: Locale
}

const flags: { [key in string]: string } = {
  en: 'https://cdn.jagofon.com/flags/4x3/gb.svg',
  id: 'https://cdn.jagofon.com/flags/4x3/id.svg',
}

const { setLocale, locales: appLocales, locale: localeCode } = useI18n()

const locales: LocaleMap = appLocales.value.reduce((acc, locale) => {
  acc[locale.code] = {
    name: locale.name!,
    code: locale.code,
    flag: flags[locale.code],
  }

  return acc
}, {} as LocaleMap)

const locale = computed(() => locales[localeCode.value])
const items: DropdownItem[] = Object.values(locales).map((locale) => ({
  label: locale.name,
  click: () => setLocale(locale.code),
}))
</script>

<template>
  <UDropdown :items="[items]">
    <template #default>
      <UButton color="gray" variant="soft" trailing-icon="i-far-angle-down">
        <NuxtImg
          :src="locale.flag"
          :alt="`${locale.name} Locale Flag`"
          class="h-4 w-6"
        />
      </UButton>
    </template>
  </UDropdown>
</template>
