import type { NavigationItem } from '~/types/api'

export default function useHeader() {
  // UI State
  const menuOpen = useState('sidebar', () => false)
  const closeMenu = () => (menuOpen.value = false)
  const openMenu = () => (menuOpen.value = true)
  const toggleMenu = () => (menuOpen.value = !menuOpen.value)

  // ???
  useHead({
    bodyAttrs: {
      class: {
        'overflow-hidden': menuOpen.value,
      },
    },
  })

  // Data
  const navRepo = useNuxtApp().$api.navigation
  const { data: links } = useAsyncData(
    'navigation',
    () => navRepo.getNavigation(),
    {
      default: () => [] as NavigationItem[],
    },
  )

  return {
    menuOpen,
    toggleMenu,
    closeMenu,
    openMenu,
    links,
  }
}
