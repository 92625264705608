<script setup lang="ts">
import type { QuickSearchResult } from '~/types/api/search'

const open = ref<boolean>(false)
const search = ref<HTMLElement>()
onClickOutside(search, () => {
  open.value = false
})

const query = ref('')
const api = useNuxtApp().$api.product
const {
  data: results,
  status,
  execute,
} = useLazyAsyncData(
  () => {
    if (!query.value) {
      return Promise.resolve({
        suggestions: [],
        items: [],
      })
    }

    return api.getQuickSearchResults(query.value)
  },
  {
    immediate: false,
    default: () =>
      ({
        suggestions: [],
        items: [],
      }) as QuickSearchResult,
  },
)

watchDebounced(
  query,
  () => {
    execute()

    useSegment().track('Products Searched', {
      query: query.value,
    })
  },
  {
    debounce: 500,
    maxWait: 2000,
  },
)
</script>

<template>
  <div ref="search" class="relative">
    <UInput
      v-model="query"
      :loading="status === 'pending'"
      :placeholder="$t('navigation.search.placeholder')"
      icon="i-far-magnifying-glass"
      trailing
      :ui="{
        base: 'h-full md:p-4',
        rounded: 'rounded',
        icon: {
          size: {
            sm: 'size-4',
          },
        },
      }"
      class="h-full"
      @focus="open = true"
    />
    <div
      v-show="open"
      class="absolute top-full z-10 mt-2 max-h-80 w-full divide-y overflow-y-scroll rounded bg-white shadow-md"
    >
      <div v-if="results.suggestions.length > 0" class="p-2">
        <label class="text-xs font-bold">{{
          $t('navigation.categories')
        }}</label>
        <div class="text-sm font-medium text-gray-900">
          <NuxtLink
            v-for="suggestion in results.suggestions"
            :key="suggestion.url"
            :to="suggestion.url"
            class="flex justify-between px-2 py-1 hover:underline"
          >
            <p>{{ suggestion.name }}</p>
            <UIcon name="i-far-arrow-right" class="size-3" />
          </NuxtLink>
        </div>
      </div>
      <div v-if="results.items.length > 0" class="p-2">
        <label class="text-xs font-bold">{{ $t('navigation.products') }}</label>
        <div class="space-y-4 text-sm font-medium">
          <NuxtLink
            v-for="item in results.items"
            :key="item.id"
            :to="`/product/${item.id}`"
            class="flex gap-2 px-2"
          >
            <NuxtPicture
              :src="item.image"
              :alt="item.model"
              :img-attrs="{ class: 'size-12 object-contain object-center' }"
              sizes="48px"
              quality="60"
              :modifiers="{ trim: '10' }"
              loading="lazy"
            />
            <div class="space-y-1">
              <p>
                {{ item.model }} - {{ item.specs.storage }} -
                {{ item.specs.color }}
              </p>
              <p class="text-xs">
                {{ $n(item.price, 'currency') }}
                <s v-if="item.parentPrice > item.price" class="font-normal">
                  {{ $n(item.parentPrice, 'currency') }}
                </s>
              </p>
              <div class="flex gap-2">
                <UITag v-for="tag in item.tags" :key="`${item.id}:${tag}`">
                  {{ tag.value }}
                </UITag>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
      <div
        v-if="!results.suggestions && !results.items && query !== ''"
        class="p-2"
      >
        {{ $t('navigation.search.no-results-for-query', [query]) }}
      </div>
    </div>
  </div>
</template>
