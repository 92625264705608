const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

function isSize(size: keyof typeof screens) {
  return useMediaQuery(`(min-width: ${screens[size]}px)`).value
}

export default function useScreen() {
  return {
    isSize,
  }
}
