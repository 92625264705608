<script setup lang="ts">
import { NuxtLink } from '#components'

const year = new Date().getFullYear()

const $api = useNuxtApp().$api

const socialDetails: {
  [key: string]: { name: string; color: string; icon: string }
} = {
  facebook: {
    name: 'Jagofon Facebook Link',
    color: 'text-[#4267b2]',
    icon: 'i-fab-facebook-f',
  },
  instagram: {
    name: 'Jagofon Instagram Link',
    color: 'text-[#d73c6a]',
    icon: 'i-fab-instagram',
  },
  linkedin: {
    name: 'Jagofon LinkedIn Link',
    color: 'text-[#0976b4]',
    icon: 'i-fab-linkedin-in',
  },
  google: {
    name: 'Jagofon Google Link',
    color: 'text-[#ea4335]',
    icon: 'i-fab-google',
  },
}
const socials = (await $api.social.getSocials()).map((social) => ({
  ...social,
  ...socialDetails[social.type],
}))

const { t } = useI18n()
const links = computed(() => [
  {
    label: t('navigation.about-us'),
    class: 'md:col-span-2',
    children: [
      { label: t('navigation.who-we-are'), to: '/about-us' },
      { label: 'Blog', to: 'https://blog.jagofon.com/' },
      {
        label: t('navigation.how-to-purchase'),
        to: 'https://blog.jagofon.com/post/tutorial-beli-hp-di-website-jagofon/',
        target: '_blank',
      },
      { label: t('navigation.trade-in'), to: '/resale/trade-in' },
    ],
  },
  {
    label: t('navigation.policies'),
    class: 'md:col-span-2',
    children: [
      {
        label: t('global.terms-of-service'),
        to: '/policy/terms-of-service',
      },
      { label: t('navigation.privacy-policy'), to: '/policy/privacy-policy' },
    ],
  },
  {
    label: t('navigation.contact-us'),
    class: 'md:col-span-5',
    children: [
      { label: t('navigation.send-a-message'), to: '/contact-us' },
      {
        label: '+62 812 3000 7451',
        to: 'https://wa.me/6281230007451',
        target: '_blank',
      },
      {
        label:
          'South Quarter, Jl. R.A. Kartini No.Kav. 8, Cilandak, Jakarta Selatan, DKI Jakarta 12430',
        to: 'https://maps.app.goo.gl/SGACGcoS1jN4fZat5',
        target: '_blank',
      },
    ],
  },
])

const propositions = computed(() => [
  t('propositions.express-delivery'),
  t('propositions.secure-transaction'),
  t('propositions.quality-guaranteed'),
])

const paymentMethods = {
  bri: undefined,
  bni: undefined,
  mandiri: undefined,
  ovo: undefined,
  alfamart: undefined,
  spaylater: undefined,
  kredivo: 'https://kredivo.com/faqs',
  akulaku: undefined,
  uangme: undefined,
  indodana: undefined,
}
</script>

<template>
  <div class="bg-gray-800 px-6 py-8 text-white">
    <div class="container grid gap-6 md:grid-cols-3">
      <div
        v-for="(proposition, i) in propositions"
        :key="i"
        class="flex items-center justify-center gap-4"
      >
        <div class="flex rounded-full border border-primary-400 p-1">
          <UIcon name="i-far-check" class="size-4 text-primary-400" />
        </div>
        <span class="text-sm capitalize">{{ proposition }}</span>
      </div>
    </div>
  </div>
  <footer class="text-black">
    <div class="container grid grid-cols-1 gap-y-6 px-0 py-12 md:grid-cols-12">
      <div class="flex flex-col items-center gap-y-3 md:col-span-3">
        <div
          class="flex w-full items-center justify-center px-12 text-center md:px-6"
        >
          <NuxtImg
            src="https://cdn.jagofon.com/logo/logo-green.svg"
            alt="Jagofon Logo"
            class="h-20 w-full"
            loading="lazy"
          />
        </div>
        <p class="text-gray-400">{{ t('global.follow') }}</p>
        <div class="flex items-center justify-center gap-x-6">
          <NuxtLink
            v-for="{ icon, color, type, url, name } in socials"
            :key="type"
            :to="url"
            :aria-label="name"
          >
            <UIcon :name="icon" :class="color" class="size-5" />
            <span class="hidden">{{ name }}</span>
          </NuxtLink>
        </div>
      </div>
      <div
        v-for="(link, i) in links"
        :key="`footer-link-${i}`"
        class="px-6"
        :class="link.class"
      >
        <h2 class="text-lg font-bold capitalize">{{ link.label }}</h2>
        <ul v-if="link.children">
          <li
            v-for="(child, j) in link.children"
            :key="`footer-link-${i}-${j}`"
            class="leading-relaxed"
          >
            <UButton
              variant="link"
              color="black"
              :padded="false"
              :to="child.to"
              :target="child.target"
              no-prefetch
            >
              {{ child.label }}
            </UButton>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="container flex flex-col items-center justify-between gap-3 border-t px-3 py-6 md:flex-row"
    >
      <p class="text-xs">
        {{ t('navigation.all-rights-reserved', { year }) }}
      </p>
      <div
        class="flex flex-wrap justify-center gap-2 md:justify-end md:gap-y-0"
      >
        <component
          :is="link ? NuxtLink : 'div'"
          v-for="(link, method) in paymentMethods"
          :key="method"
          :to="link"
          target="_blank"
          rel="noindex nofollow"
        >
          <NuxtPicture
            :src="`https://cdn.jagofon.com/payment-methods/${method}.png`"
            :alt="`${method} logo`"
            sizes="64px"
            quality="60"
            loading="lazy"
            :img-attrs="{ class: 'h-8 w-16 object-contain' }"
          />
        </component>
      </div>
    </div>
  </footer>
</template>
