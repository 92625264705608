<template>
  <div>
    <AppHeader class="relative z-20" />

    <main class="bg-gray-50">
      <slot />
    </main>

    <AppFooter />

    <UNotifications />
    <UModals />
  </div>
</template>
